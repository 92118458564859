<template>
  <div class="tenants-options">
    <div class="container">
      <h2 v-if="pageRent" class="title">{{ $t("variantsTitle") }}</h2>

      <div v-if="pageRent" class="tenants-options__address">
        {{ pageRent.text }}
      </div>

      <div class="tenants-options__sites tenants-sites">
        <h3>{{ $t("filial") }}</h3>

        <div class="tenants-sites__content">
          <div class="tenants-sites__menu">
            <div class="tenants-sites__name filial">
              {{ $t("filialForm1") }}
            </div>
            <div class="tenants-sites__name name">{{ $t("filialForm2") }}</div>
            <div class="tenants-sites__name size">
              {{ $t("filialForm3") }}
            </div>
          </div>

          <table class="tenants-table">
            <tbody>
              <tr
                v-for="(tenant, index) in tenants"
                :key="index"
                class="tenants-table__item"
              >
                <td class="tenants-table__filial">
                  {{ tenant.attributes.name }}
                </td>
                <td class="tenants-table__address">
                  {{ tenant.attributes.address }}
                </td>
                <td class="tenants-table__size">
                  {{ tenant.attributes.size }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTenants } from "@/api/tenants";
export default {
  name: "TenantsOptions",
  props: {
    pageRent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tenants: [],
    };
  },
  created() {
    getTenants().then((res) => {
      this.tenants = res;
    });
  },
};
</script>
